import Logo from "@components/Logo";
import Link from "next/link";

const Footer = () => {
  return (<>
    <footer className='footer pt-5'>
      <div className="join-us w-75 text-start mx-auto">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h5 className="">Join <Logo /> for free!</h5>
            <p>Host your contests with 100% flexibility and no hidden charges.</p>
          </div>
          <div className="col-md-4 justify-content-end d-flex">
            <Link href="/contests?create=1">
              <a className="btn btn-secondary text-light">Get started</a>
            </Link>
          </div>
        </div>
      </div>
      <div className="clipped">
        <div className="container">
          <div className="row text-center align-items-center">
            <div className='col-md-3 mb-2 text-light'>
              <Logo />
            </div>
            <div className="page-links col-md-6 mb-2">
              <Link href="/terms">
                <a>Terms of Use</a>
              </Link>
              <Link href="/privacy">
                <a>Privacy Policy</a>
              </Link>
              <Link href="/faq">
                <a>FAQ</a>
              </Link>
            </div>
            <div className="social-links col-md-3 mb-2">
              <a href="https://twitter.com/contest_ish">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM23.3054 12.4751C23.3125 12.6326 23.3159 12.7908 23.3159 12.9497C23.3159 17.8025 19.6221 23.3984 12.8669 23.3987H12.8672H12.8669C10.793 23.3987 8.86304 22.7908 7.23779 21.749C7.52515 21.783 7.81763 21.7998 8.11377 21.7998C9.83447 21.7998 11.418 21.2129 12.675 20.2278C11.0674 20.198 9.71191 19.1362 9.24414 17.677C9.46802 17.72 9.69824 17.7434 9.93433 17.7434C10.2695 17.7434 10.5942 17.6982 10.9028 17.614C9.22241 17.2776 7.95654 15.7925 7.95654 14.0142C7.95654 13.9976 7.95654 13.9827 7.95703 13.9673C8.4519 14.2424 9.01782 14.408 9.62036 14.4265C8.63428 13.7686 7.98608 12.6438 7.98608 11.3696C7.98608 10.6968 8.16797 10.0664 8.4834 9.52368C10.2944 11.7458 13.001 13.2073 16.0532 13.3608C15.9902 13.0918 15.9578 12.8115 15.9578 12.5234C15.9578 10.4961 17.6025 8.85132 19.6306 8.85132C20.687 8.85132 21.6411 9.29785 22.3113 10.0117C23.1479 9.84668 23.9336 9.54102 24.6433 9.12036C24.3687 9.97754 23.7866 10.6968 23.0283 11.1516C23.7712 11.0627 24.4792 10.8657 25.1372 10.5732C24.6458 11.3098 24.0225 11.9568 23.3054 12.4751Z"
                    fill="white" />
                </svg>
              </a>
              {/* Instagram */}
              {/* <a href="">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.0625 16C19.0625 17.6914 17.6914 19.0625 16 19.0625C14.3086 19.0625 12.9375 17.6914 12.9375 16C12.9375 14.3086 14.3086 12.9375 16 12.9375C17.6914 12.9375 19.0625 14.3086 19.0625 16Z"
                    fill="white" />
                  <path
                    d="M23.1621 10.5815C23.0149 10.1826 22.78 9.82153 22.4749 9.52515C22.1785 9.21997 21.8176 8.98511 21.4185 8.83789C21.0947 8.71216 20.6084 8.5625 19.7126 8.52173C18.7437 8.47754 18.4531 8.46802 16 8.46802C13.5466 8.46802 13.2561 8.47729 12.2874 8.52148C11.3916 8.5625 10.905 8.71216 10.5815 8.83789C10.1824 8.98511 9.82129 9.21997 9.52515 9.52515C9.21997 9.82153 8.98511 10.1824 8.83765 10.5815C8.71191 10.9053 8.56226 11.3918 8.52148 12.2876C8.47729 13.2563 8.46777 13.5469 8.46777 16.0002C8.46777 18.4534 8.47729 18.7439 8.52148 19.7129C8.56226 20.6086 8.71191 21.095 8.83765 21.4187C8.98511 21.8179 9.21973 22.1787 9.5249 22.4751C9.82129 22.7803 10.1821 23.0151 10.5813 23.1624C10.905 23.2883 11.3916 23.438 12.2874 23.4788C13.2561 23.523 13.5464 23.5322 15.9998 23.5322C18.4534 23.5322 18.7439 23.523 19.7124 23.4788C20.6082 23.438 21.0947 23.2883 21.4185 23.1624C22.2197 22.8533 22.853 22.22 23.1621 21.4187C23.2878 21.095 23.4375 20.6086 23.4785 19.7129C23.5227 18.7439 23.532 18.4534 23.532 16.0002C23.532 13.5469 23.5227 13.2563 23.4785 12.2876C23.4377 11.3918 23.2881 10.9053 23.1621 10.5815ZM16 20.7178C13.3943 20.7178 11.282 18.6057 11.282 16C11.282 13.3943 13.3943 11.2822 16 11.2822C18.6055 11.2822 20.7178 13.3943 20.7178 16C20.7178 18.6057 18.6055 20.7178 16 20.7178ZM20.9043 12.1982C20.2954 12.1982 19.8018 11.7046 19.8018 11.0957C19.8018 10.4868 20.2954 9.99316 20.9043 9.99316C21.5132 9.99316 22.0068 10.4868 22.0068 11.0957C22.0066 11.7046 21.5132 12.1982 20.9043 12.1982Z"
                    fill="white" />
                  <path
                    d="M16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C24.8352 32 32 24.8352 32 16C32 7.16479 24.8352 0 16 0ZM25.1321 19.7878C25.0876 20.7659 24.9321 21.4336 24.7051 22.0181C24.2278 23.2522 23.2522 24.2278 22.0181 24.7051C21.4338 24.9321 20.7659 25.0874 19.7881 25.1321C18.8083 25.1768 18.4954 25.1875 16.0002 25.1875C13.5049 25.1875 13.1921 25.1768 12.2122 25.1321C11.2344 25.0874 10.5664 24.9321 9.98218 24.7051C9.3689 24.4744 8.81372 24.1128 8.35474 23.6453C7.88745 23.1865 7.52588 22.6311 7.29517 22.0181C7.06812 21.4338 6.9126 20.7659 6.86816 19.7881C6.823 18.8081 6.8125 18.4951 6.8125 16C6.8125 13.5049 6.823 13.1919 6.86792 12.2122C6.91235 11.2341 7.06763 10.5664 7.29468 9.98193C7.52539 9.3689 7.88721 8.81348 8.35474 8.35474C8.81348 7.88721 9.3689 7.52563 9.98193 7.29492C10.5664 7.06787 11.2341 6.9126 12.2122 6.86792C13.1919 6.82324 13.5049 6.8125 16 6.8125C18.4951 6.8125 18.8081 6.82324 19.7878 6.86816C20.7659 6.9126 21.4336 7.06787 22.0181 7.29468C22.6311 7.52539 23.1865 7.88721 23.6455 8.35474C24.1128 8.81372 24.4746 9.3689 24.7051 9.98193C24.9324 10.5664 25.0876 11.2341 25.1323 12.2122C25.177 13.1919 25.1875 13.5049 25.1875 16C25.1875 18.4951 25.177 18.8081 25.1321 19.7878Z"
                    fill="white" />
                </svg>
              </a> */}
              {/* Facebook */}
              {/* <a href="">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M32 16C32 7.1625 24.8375 0 16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C16.0938 32 16.1875 32 16.2812 31.9937V19.5438H12.8438V15.5375H16.2812V12.5875C16.2812 9.16875 18.3688 7.30625 21.4188 7.30625C22.8813 7.30625 24.1375 7.4125 24.5 7.4625V11.0375H22.4C20.7437 11.0375 20.4188 11.825 20.4188 12.9812V15.5312H24.3875L23.8687 19.5375H20.4188V31.3813C27.1063 29.4625 32 23.3063 32 16Z"
                    fill="white" />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>)
}

export default Footer;
