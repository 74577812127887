import Icon from "@components/Icon"
import Link from "next/link"
import { UncontrolledTooltip } from "reactstrap"

const UpgradeButton = () => {
    return (
        <>
            <Link href="/pricing">
                <a className="btn btn-upgrade d-flex align-items-center text-uppercase text-black" id="premium_upgrade_tooltip">
                    <Icon name="star" className="me-2 text-warning"/> <span>Upgrade</span>
                </a>
            </Link>

            <UncontrolledTooltip target="#premium_upgrade_tooltip">This is a premium feature, upgrade to enjoy all premium benefits!</UncontrolledTooltip>
        </>
    )
}

export default UpgradeButton